import { RowSelectionState } from "@tanstack/react-table";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useSidePanelClose } from "PFComponents/side_panel/use_side_panel_close";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useReassignActionApi,
  useReassignActionState,
  useReassignSubmitApi
} from "../../parts/overview/booking_module_calendar/context/reassign_action_context_provider";
import { DetailsHeader } from "../details_panel/detail_view/details_header";
import { ReassignSelectOption } from "../reassign_booking_modal/bookings_to_reassign_selector";
import { ReassignContent } from "./components/reassign_content";
import { ReassignSidePanelFooter } from "./components/reassign_side_panel_footer";

type ReassignSidePanelProps = {
  profileFullName: string;
};

export const ReassignSidePanel = ({ profileFullName }: ReassignSidePanelProps) => {
  const { t } = useTranslation("bookingModule");
  const { data, isSidePanelOpened } = useReassignActionState();
  const { closeSidePanel } = useReassignActionApi();
  const { onSubmit } = useReassignSubmitApi();
  const { isClosing, onSidePanelClose } = useSidePanelClose({
    onClose: () => {
      closeSidePanel();
      setReassignSelectOption(ReassignSelectOption.ThisBooking);
    }
  });

  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>({});
  const [reassignSelectOption, setReassignSelectOption] = useState<ReassignSelectOption>(
    ReassignSelectOption.ThisBooking
  );

  if (!data) {
    return null;
  }
  const selectedRowIds = Object.keys(rowSelectionState);
  const handleSubmit = () => {
    if (selectedRowIds.length === 1) {
      onSubmit(reassignSelectOption, parseInt(selectedRowIds[0]));
    }
    setReassignSelectOption(ReassignSelectOption.ThisBooking);
  };

  const handleClose = () => {
    closeSidePanel();
    setReassignSelectOption(ReassignSelectOption.ThisBooking);
  };

  return (
    <SidePanel
      size="medium"
      show={isSidePanelOpened}
      isSidePanelClosing={isClosing}
      onClose={handleClose}
      title={<DetailsHeader title={data.booking.title} subtitle={t("bookings.reassign.reassignAction")} />}
      footerRenderer={() => (
        <ReassignSidePanelFooter
          submitDisabled={selectedRowIds.length !== 1}
          onClose={onSidePanelClose}
          onSubmit={handleSubmit}
        />
      )}
    >
      <ReassignContent
        booking={data.booking}
        activity={data.activity}
        isEngagement={data.isEngagement}
        availability={data?.availability}
        profileFullName={profileFullName}
        onTargetProfileChange={setRowSelectionState}
        rowSelectionState={rowSelectionState}
        reassignOption={reassignSelectOption}
        setReassignOption={setReassignSelectOption}
      />
    </SidePanel>
  );
};
