import { useBooking } from "PFCore/hooks/queries";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useCallback } from "react";

import { DetailsPanelData } from "./details_panel_context/details_panel_context";

interface UsePrerequisiteChecker {
  data?: DetailsPanelData;
}
type UsePrerequisiteCheckerReturn = ({ type }: Partial<Pick<DetailsPanelData, "type">>) => boolean;

export const usePrerequisiteChecker = ({ data }: UsePrerequisiteChecker): UsePrerequisiteCheckerReturn => {
  const { id, type, profileDetails } = data || {};
  const { data: booking } = useBooking(id, profileDetails?.id, { enabled: !!id && type === "booking" });
  const { data: profile } = useProfile(id || -1, { enabled: !!id && type === "profile" });
  const { getCategory } = useBookingCategories();

  return useCallback(
    ({ type }) => {
      switch (type) {
        case "booking":
          return !!booking && !!getCategory(booking);
        case "profile":
          return !!profile;
        default:
          return true;
      }
    },
    [booking, getCategory, profile]
  );
};
