import { useBooking } from "PFCore/hooks/queries";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { Booking, BookingTemplate, Profile } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { useBookingSiblings } from "../booking_detail_content/booking_siblings/use_booking_siblings";

type BookingDetailSidePanelContextType = {
  initialBooking: Booking | undefined;
  profile: Profile | undefined;
  siblingBookings: Booking[] | undefined;
  bookingTemplate: BookingTemplate | undefined;
  isInitialBookingLoading: boolean;
  isSiblingBookingsFetching: boolean;
  isBookingTemplateFetching: boolean;
  currentSiblingBookingIndex: number | undefined;
  setCurrentSiblingBookingIndex: Dispatch<SetStateAction<number>>;
  invalidateBookingSiblings: VoidFunction;
};

const BookingDetailSidePanelContext = createContext({} as BookingDetailSidePanelContextType);

export type BookingDetailSidePanelContextProviderProps = {
  bookingId: number;
  profileId?: number;
  enabled: boolean;
};

export const BookingDetailSidePanelContextProvider = ({
  children,
  bookingId,
  profileId,
  enabled
}: PropsWithChildren<BookingDetailSidePanelContextProviderProps>) => {
  const [currentSiblingBookingIndex, setCurrentSiblingBookingIndex] = useState<number | undefined>(undefined); //undefined to not fetch booking data unnecessarily

  const { data: initialBooking, isLoading: isInitialBookingLoading } = useBooking(bookingId, profileId, {
    enabled: enabled && !!bookingId
  });

  const { data: profile } = useProfile(initialBooking?.profile_id, {
    enabled: enabled && !!initialBooking?.profile_id
  });

  const isRepeatedBooking = !!initialBooking?.booking_template_id;
  const {
    data: siblingBookings,
    isFetching: isSiblingBookingsFetching,
    invalidate: invalidateBookingSiblings
  } = useBookingSiblings({
    bookingId: initialBooking?.id,
    profileId: profile?.id,
    activityId: initialBooking?.activity?.id,
    bookingTemplateId: initialBooking?.booking_template_id,
    enabled: !!initialBooking && !!profile
  });
  const { data: bookingTemplate, isFetching: isBookingTemplateFetching } = useBookingTemplate(
    initialBooking?.booking_template_id,
    {
      enabled: isRepeatedBooking
    }
  );

  useEffect(() => {
    // TODO: [SP-574] Display current booking after editing the siblings, not the initial one (not bookingId)
    const index = (siblingBookings ?? []).findIndex(({ id }) => id === bookingId);
    setCurrentSiblingBookingIndex(index < 0 ? undefined : index);
  }, [siblingBookings, bookingId]);

  const value = useMemo(
    () => ({
      initialBooking,
      profile,
      siblingBookings,
      bookingTemplate,
      isInitialBookingLoading,
      isSiblingBookingsFetching,
      isBookingTemplateFetching,
      currentSiblingBookingIndex,
      setCurrentSiblingBookingIndex,
      invalidateBookingSiblings
    }),
    [
      initialBooking,
      profile,
      siblingBookings,
      bookingTemplate,
      isInitialBookingLoading,
      isSiblingBookingsFetching,
      isBookingTemplateFetching,
      currentSiblingBookingIndex,
      invalidateBookingSiblings
    ]
  );

  return (
    <BookingDetailSidePanelContext.Provider value={value}>{children}</BookingDetailSidePanelContext.Provider>
  );
};

export const useBookingDetailSidePanelContext = () => {
  const context = useContext(BookingDetailSidePanelContext);
  if (!context) {
    throw new Error(
      "useBookingDetailSidePanelContext must be used within a BookingDetailSidePanelContextProvider"
    );
  }
  return context;
};
