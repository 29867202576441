import { IRoleWatcher } from "PFApp/activities/types/watchers";
import {
  ActivityCustomField,
  ActivityPureCustomValue,
  ActivitySearchHighlights,
  Availability,
  AvailabilityRule,
  CustomValue,
  Id,
  IMatchScores,
  PostUntilChangeReason,
  Profile,
  ProfileBasicInformation,
  ShortlistMinimized
} from "PFTypes";

export type ActivityProfile = {
  profile_id: number;
  activity_id?: number;
  id?: Id;
  profile: Profile & { active?: boolean };
};

export enum ActivityProfileType {
  Owner = "owner",
  Reviewer = "reviewer",
  Assignee = "assignee",
  Shortlist = "shortlist"
}

export type Owner = ActivityProfile & { principal: boolean };
export type Reviewer = ActivityProfile & { unbiased: boolean };
type Assignee = Pick<ActivityProfile, "id" | "profile">;

export type Metadata = Record<string, any> & {
  availability?: AvailabilityRule;
  filters?: { pcf_?: {} };
  interest_questions?: string[];
  interest_statement_enabled?: boolean;
  location?: string;
};

type ActivityStatistics = Record<
  "matches_count" | "interests_count" | "saves_count" | "shares_count",
  number
>;

export type ActivityState = "new" | "draft" | "complete";
export type ActivityReview = {
  deadline?: string;
  completed_at?: string;
  completed_by?: {
    id: number;
    first_name: string;
    last_name: string;
  };
  state: "complete" | "in_progress";
};

export type Activity = {
  id: number;
  audiences?: string[][];
  availability?: Availability;
  booking_category_id?: Id | null;
  coowned: boolean;
  reviewed_by_requester?: boolean;
  custom_fields: ActivityCustomField[];
  developmental?: boolean;
  owners: Owner[];
  reviewers: Reviewer[];
  assignee: Assignee | null;
  template_id: number;
  name: string;
  description: string;
  duration?: { end_date: string; start_date: string };
  subtemplate_key?: string;
  metadata: Metadata;
  statistics: ActivityStatistics;
  parent_activity_id?: number;
  interest_id?: number;
  participants: { profile: ProfileBasicInformation }[];
  private: boolean;
  profile: ProfileBasicInformation;
  review?: ActivityReview;
  shortlist_assignment?: ShortlistMinimized;
  state: ActivityState;
  skills_framework_ids?: Id[] | null;
  workflow_state: "booked" | "pending" | "exception" | string;
  children_workflow_states_tally?: Record<string, number>;
  source: "ui" | "jml";
  communication_type?: "group" | "direct";
  min_required_count: number;
  post_until: string;
  assigned: boolean;
  expires_soon: boolean;
  created_at: string;
  updated_at: string;
  post_until_change_reason?: PostUntilChangeReason | null;
  matched_at: string;
  reviewer?: boolean;
  scenarios_count: number;
  vacancies?: {
    booked: number;
    closed: number;
    confirmed: number;
    total: number;
  };
  shortlists?: { total: number };
  watchers?: IRoleWatcher[];
};

export type PostUntilChangeReasonData = Omit<PostUntilChangeReason, "text"> & { text?: string };

type ActivityCustomFieldData = {
  type: ActivityCustomField["type"];
  values: Pick<ActivityPureCustomValue, "id" | "text" | "value" | "importance" | "required_experience">[];
};

export type ActivityData = Omit<
  Partial<Activity>,
  "post_until_change_reason" | "metadata" | "custom_fields"
> & {
  custom_fields?: ActivityCustomFieldData[];
} & Partial<{
    post_until_change_reason: PostUntilChangeReasonData | null;
  }> &
  Partial<{
    metadata: Omit<Activity["metadata"], "availability"> & Partial<{ availability: AvailabilityRule | {} }>;
  }>;

export type MarketplaceActivityCounter = "shares" | "saves" | "interests" | "matches";

export type MarketplaceActivityStatistics = Record<MarketplaceActivityCounter, number>;

type MarketplaceActivityAttributes = {
  matched_by: MatchPreference[];
  shared_id: number;
  saved_id: number;
  unread: boolean;
};

export type MarketplaceActivity = {
  id: Id;
  activity: Pick<Activity, "id" | "name" | "interest_id" | "post_until" | "expires_soon"> &
    MarketplaceActivityAttributes;
  scores: IMatchScores | null;
  optional_fields?: {
    highlights?: ActivitySearchHighlights;
  };
};

export enum MatchPreference {
  Duration = "duration",
  Skills = "skills",
  Type = "type"
}

type InterestDetailsData = {
  interest_details: {
    answer1: boolean | null;
    answer2: boolean | null;
    answer3: boolean | null;
    statement?: string;
  } | null;
};

export type MatchedActivity = {
  id: Id;
  activity: Omit<
    Activity,
    "audiences" | "source" | "assignee" | "owners" | "reviewers" | "shortlist_assignment"
  > &
    MarketplaceActivityAttributes &
    InterestDetailsData;
  scores: IMatchScores | null;
  profile: {
    id: Id;
  };
  custom_values: {
    id: Id;
    direct: CustomValue[];
    indirect: CustomValue[];
    related: CustomValue[];
  };
  optional_fields?: {
    highlights?: ActivitySearchHighlights;
  };
};

export type RefreshedActivity = Omit<MatchedActivity, "activity"> & {
  activity: Omit<
    Activity,
    "audiences" | "source" | "assignee" | "owners" | "reviewers" | "shortlist_assignment"
  >;
};
