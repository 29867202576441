import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import { BookingDetailActivity } from "PFApp/booking/components/booking_detail_activity";
import { setTimeToMidnight } from "PFApp/booking/parts/overview/calendar/calendar.utils";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useBooking } from "PFCore/hooks/queries";
import { OverbookedDates, OverbookingsManagementMode } from "PFCore/services/bookings_suggested_changes";
import { AvailabilityRule } from "PFTypes";
import { useEffect, useState } from "react";
import { useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useBookingActivityContext } from "../../parts/providers/booking_activity_context_provider";
import { useDetailsPanelOrderContext } from "../details_panel";
import { AvailabilityInfo } from "./availability_info";
import css from "./booking_form.module.scss";
import { getHeaderTitleTranslationKey } from "./booking_form.utils";
import { useBookingFormContext } from "./booking_form_context_provider";
import { BookingFormGlobalInputs } from "./booking_form_global_inputs";
import { BookingFormValues } from "./booking_form_provider";
import { BookingRangeColumn } from "./booking_range_column";
import { BookingTypeSelector } from "./booking_type_selector";
import { EngagementSelector, useShouldDisplayEngagementSelect } from "./engagement_selector";
import { Footer } from "./footer/footer";
import { useOverbookingsManagementApiContext } from "./overbookings_management_context/overbookings_management_context";
import { useAreBookingsValid } from "./use_are_bookings_valid";
import { BookingFormData, BookingFormDataEditOrCloneMode, BookingFormMode } from "./use_booking_form";
import { usePotentialWarnings } from "./use_potential_warnings";
import { WorkforceMemberSelect } from "./workforce_member_select";
import { isGroupOption } from "./workforce_member_select/workforce_member_select_dropdown";

export type BookingFormProps = {
  show: boolean;
  onClose?: () => void;
  onOpenOverbookingsManagementModal: (
    mode: OverbookingsManagementMode,
    overbookedDates: OverbookedDates
  ) => void;
  initialData?: BookingFormData;
  mode: BookingFormMode;
  goToDate?: (date: Date) => void;
};

export const BookingForm = ({
  show,
  onClose,
  initialData = {},
  mode,
  onOpenOverbookingsManagementModal,
  goToDate
}: BookingFormProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { activity, parentActivity, isLoading: isActivityLoading } = useBookingActivityContext();
  const { loadingState } = useBookingFormContext();
  const { setOverbookedBookings, clearOverbookedBookings } = useOverbookingsManagementApiContext();
  const areBookingsValid = useAreBookingsValid();
  const [activeBookingIndex, setActiveBookingsIndex] = useState<number>(0);

  const bookingId =
    mode === BookingFormMode.Create ? undefined : (initialData as BookingFormDataEditOrCloneMode).bookingId;
  const { data: initialBooking } = useBooking(bookingId, initialData?.profileId, { enabled: !!bookingId });

  const { findIndexOfBookingForm } = useDetailsPanelOrderContext();
  const [workforceMember, bookings, activityId] = useWatch<BookingFormValues>({
    name: ["workforceMember", "bookings", "activityId"]
  });
  const { isSubmitting, isValid, errors } = useFormState();

  const isCreateMode = mode === BookingFormMode.Create;
  const isGroupBooking = !!initialBooking?.booking_group_id || isGroupOption(workforceMember);
  const shouldDisplayEngagementSelect = useShouldDisplayEngagementSelect(isGroupBooking);

  const { overbookings, misalignments, overbookingIntersectionsDates } = usePotentialWarnings({
    mode
  });

  const sidePanelTitleKey = getHeaderTitleTranslationKey(mode);

  const isBookingFormLoading = !isEmpty(loadingState) && some(loadingState, (value) => !!value);
  const isFormValid = isValid && areBookingsValid;
  const isSubmitDisabled = !isFormValid || isSubmitting || isBookingFormLoading || !isEmpty(errors);

  useEffect(() => {
    if (mode === BookingFormMode.Create) {
      if ((overbookingIntersectionsDates ?? []).length > 0 && bookings.length > 0) {
        setOverbookedBookings(workforceMember?.id, activityId, bookings);
        goToDate && goToDate(setTimeToMidnight(new Date(bookings[activeBookingIndex].startDate)));
      } else {
        clearOverbookedBookings();
      }
    }
  }, [mode, overbookingIntersectionsDates, bookings]);

  return (
    <SidePanel
      show={show}
      zIndex={findIndexOfBookingForm() + 1}
      onClose={onClose}
      title={t(sidePanelTitleKey)}
      footerRenderer={({ onSidePanelClose }) => (
        <Footer
          profileId={workforceMember?.id}
          activity={activity}
          misalignments={misalignments ?? []}
          overbookings={overbookings ?? []}
          overbookingIntersectionsDates={overbookingIntersectionsDates}
          onClose={onSidePanelClose}
          isSubmitDisabled={isSubmitDisabled}
          isLoading={isBookingFormLoading}
          mode={mode}
          onOpenOverbookingsManagementModal={onOpenOverbookingsManagementModal}
        />
      )}
    >
      <div className={css.content}>
        <div className={css.section}>
          <WorkforceMemberSelect initialData={initialData} mode={mode} booking={initialBooking} />
          {!!activity && !shouldDisplayEngagementSelect && (
            <BookingDetailActivity
              activity={activity}
              parentActivity={parentActivity}
              isLoading={isActivityLoading}
            />
          )}
          {activity?.availability && (
            <AvailabilityInfo availability={activity.availability as AvailabilityRule} />
          )}
          {parentActivity?.id && (
            <BookingDetailActivity activity={parentActivity} isLoading={isActivityLoading} />
          )}
          {shouldDisplayEngagementSelect && (
            <EngagementSelector titleFormValueName="globalTitle" mode={mode} />
          )}
        </div>
        <BookingTypeSelector initialData={initialData} mode={mode} />
        <div className={css.section}>
          <BookingRangeColumn
            initialData={initialData}
            mode={mode}
            initialBooking={initialBooking}
            activeBookingIndex={activeBookingIndex}
            setActiveBookingIndex={setActiveBookingsIndex}
          />
        </div>
        {isCreateMode && <BookingFormGlobalInputs />}
      </div>
    </SidePanel>
  );
};
