import { RowSelectionState } from "@tanstack/react-table";
import classNames from "classnames";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import BrainLoading from "PFComponents/brain_loading/brain_loading";
import MultiToggle, { Option } from "PFComponents/multi_toggle/multi_toggle";
import { Typography } from "PFComponents/typography";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useMatchesInvalidate } from "PFCore/hooks/queries/matches/use_matches_invalidate";
import { fetchMatches } from "PFCore/services/matches";
import { Activity, Availability, Booking, OrderOption, PermissionRule } from "PFTypes";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BookingsToReassignSelector,
  ReassignSelectOption
} from "../../reassign_booking_modal/bookings_to_reassign_selector";
import { useReassignMatches } from "../hooks/use_reassign_matches";
import { prepareMatchesPayload } from "../hooks/use_reassign_matches.utils";
import { MatchesActions } from "./matches_actions";
import { MatchesTable } from "./matches_table";
import { NamedResourceTable } from "./named_resource_table";
import { NoMatchesPlaceholder } from "./no_matches_placeholder";
import css from "./reassign_content.module.scss";

export enum ProfilesTab {
  Matches = "matches",
  NamedResource = "namedResource"
}

export type RowSelection = {
  onTargetProfileChange: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  rowSelectionState: RowSelectionState;
};

type ReassignContentProps = {
  booking: Booking;
  activity: Activity | undefined;
  isEngagement: boolean | undefined;
  availability: Availability | undefined;
  profileFullName: string;
  reassignOption: ReassignSelectOption;
  setReassignOption: React.Dispatch<React.SetStateAction<ReassignSelectOption>>;
} & RowSelection;

export const ReassignContent = ({
  booking,
  activity,
  isEngagement,
  availability,
  profileFullName,
  onTargetProfileChange,
  rowSelectionState,
  reassignOption,
  setReassignOption
}: ReassignContentProps) => {
  const { t } = useTranslation("bookingModule");

  const isPermittedTo = useIsCurrentUserPermittedTo();
  const canReassignBookings = isPermittedTo(PermissionRule.ReassignBookings);

  const initialSelectedTab = isEngagement ? ProfilesTab.NamedResource : ProfilesTab.Matches;

  const [selectedTab, setSelectedTab] = useState<ProfilesTab>(initialSelectedTab);
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState<OrderOption>();
  const [isLoading, setIsLoading] = useState(false);

  const { invalidate: invalidateMatches } = useMatchesInvalidate();

  const { matchesResponse } = useReassignMatches({
    searchValue,
    order,
    booking,
    availability
  });

  const matchesResponseIsEmpty = !matchesResponse.isFetching && matchesResponse.matchesData.length === 0;

  const { data: parentActivity } = useBookingActivity(activity?.parent_activity_id, {
    enabled: !!activity?.parent_activity_id && matchesResponseIsEmpty
  });

  useEffect(() => {
    setSearchValue("");
    onTargetProfileChange({});
    setIsLoading(false);
  }, [selectedTab]);

  const profilesTabOptions: Option<ProfilesTab>[] = [
    {
      id: ProfilesTab.Matches,
      value: t("bookings.reassign.sidePanel.matches")
    },
    {
      id: ProfilesTab.NamedResource,
      value: t("bookings.reassign.sidePanel.namedResource"),
      disabled: !canReassignBookings
    }
  ];

  const handleMatchesRefresh = () => {
    setIsLoading(true);

    fetchMatches(booking.activity_id, {
      refresh: true,
      ...prepareMatchesPayload(searchValue, availability, order)
    })
      .then(() => {
        invalidateMatches();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const content = useMemo(() => {
    if (isLoading) {
      return <BrainLoading text={t("bookings.reassign.sidePanel.calculationsStarted")} />;
    }
    if (isEngagement || selectedTab === ProfilesTab.NamedResource) {
      return canReassignBookings ? (
        <NamedResourceTable
          searchValue={searchValue}
          onTargetProfileChange={onTargetProfileChange}
          rowSelectionState={rowSelectionState}
        />
      ) : null;
    }

    if (matchesResponseIsEmpty) {
      return (
        <NoMatchesPlaceholder
          activity={activity}
          parentActivity={parentActivity}
          changeSelectedTab={setSelectedTab}
          canReassignBookings={canReassignBookings}
        />
      );
    }

    return (
      <MatchesTable
        onTargetProfileChange={onTargetProfileChange}
        rowSelectionState={rowSelectionState}
        matchesResponse={matchesResponse}
      />
    );
  }, [
    isLoading,
    selectedTab,
    isEngagement,
    canReassignBookings,
    searchValue,
    matchesResponse,
    matchesResponseIsEmpty,
    activity,
    parentActivity
  ]);

  const tabsVisible = !isEngagement && canReassignBookings;

  const isCompleted = activity?.state === "complete";

  return (
    <div className={css.root}>
      <div className={classNames(css.root, css.stickyContainer)}>
        <BookingsToReassignSelector
          activityId={booking.activity_id ?? -1}
          profileId={booking.profile_id}
          currentBooking={{
            id: booking.id,
            startDate: booking.start_date,
            endDate: booking.end_date
          }}
          reassignOptionSelected={reassignOption}
          setReassignOptionSelected={setReassignOption}
          dividerEnabled
        />

        <Typography variant="bodyRegular" tag="span">
          {t("bookings.reassign.sidePanel.reassignFromTo", { profileFrom: profileFullName })}
        </Typography>
        {tabsVisible && (
          <MultiToggle<ProfilesTab>
            controlledValue={selectedTab}
            onChange={(id) => setSelectedTab(id)}
            options={profilesTabOptions}
          />
        )}
        <SearchInput
          term={searchValue || ""}
          pageKey={RecentSearchesPageKey.ReassignProfile}
          onTermChange={(term) => setSearchValue(term)}
          classes={{
            root: css.search
          }}
          disabled={
            selectedTab === ProfilesTab.Matches && (isLoading || matchesResponse.isFetching || isCompleted)
          }
          autocompleteConfig={[{ entity: SearchEntity.Profile, useAsTerm: true }]}
        />
        {selectedTab === ProfilesTab.Matches && !isCompleted && (
          <MatchesActions
            onRefresh={handleMatchesRefresh}
            isLoading={isLoading || matchesResponse.isFetching}
            lastRefreshAt={matchesResponse.lastRefreshAt}
            orderMeta={matchesResponse.orderMeta}
            onOrderChange={setOrder}
          />
        )}
      </div>
      {content}
    </div>
  );
};
