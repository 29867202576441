import { useQuery } from "@tanstack/react-query";
import { FetchBookings, fetchBookings } from "PFApp/booking/services/api";
import { fetchBookings as fetchProfileBookings } from "PFApp/profiles/services/api";
import { Booking, PaginatedCollection, QueryOptions } from "PFTypes";

import { bookingKeys } from "./query_keys";
import { useIsExternalUsage } from "./use_is_external_usage";

export const useBookings = (
  params: FetchBookings,
  options?: QueryOptions<PaginatedCollection<Booking[]>>
) => {
  const isExternalUsage = useIsExternalUsage();
  return useQuery<PaginatedCollection<Booking[]>>(
    bookingKeys.list(params, isExternalUsage),
    async () => {
      if (isExternalUsage) {
        return await fetchProfileBookings(params);
      } else {
        return await fetchBookings(params);
      }
    },
    options
  );
};
