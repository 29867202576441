import classNames from "classnames";
import { Typography, TypographyProps } from "PFComponents/typography";
import colors from "PFTheme/tokens/colors";
import { Currency, EconomicsScenario } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./budget_vs_cost_chart.module.scss";
import { BarChart } from "./parts/bar_chart";

type Unit = "m" | "k";
type BudgetVsCostChartProps = {
  budget: number;
  cost: EconomicsScenario["cost"];
  headerVariant?: TypographyProps["variant"];
};

export const getNumberInUnits = (number: number, unit?: Unit): { value: number; unit?: Unit } => {
  if (unit === "m" || number > 999999) {
    return { value: number / 1000000, unit: "m" };
  }
  if (unit === "k" || number > 999) {
    return { value: number / 1000, unit: "k" };
  }
  return { value: number };
};

export const BudgetVsCostChart = ({
  budget = 0,
  cost = { value: 0 },
  headerVariant
}: BudgetVsCostChartProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.budgetVsCost" });
  const { unit, value: budgetValue } = getNumberInUnits(budget);
  const { value: costValue } = getNumberInUnits(cost.value, unit);

  const costColor = useMemo(() => {
    if (costValue > budgetValue) {
      return colors.paletteRed0;
    }
    if (costValue / budgetValue >= 0.9) {
      return colors.paletteOrange0;
    }
    return colors.paletteEcharts1;
  }, [budgetValue, costValue]);

  const profit = budgetValue - costValue;
  const margin = Math.round((profit / budgetValue) * 100);
  const symbol = (cost.currency && Currency[cost.currency]) || "";

  return (
    <>
      <BarChart
        title={t("title")}
        headerVariant={headerVariant}
        hideCoverage
        symbol={symbol}
        unit={unit}
        series={[
          {
            name: t("budget"),
            data: [{ value: budgetValue, unit }]
          },
          {
            name: t("cost"),
            color: costColor,
            data: [{ value: costValue, unit }]
          }
        ]}
      />
      <div className={css.indicators}>
        <div className={css.indicator}>
          <Typography variant="labelRegular">{t("profit")}</Typography>
          <Typography className={classNames({ [css.negative]: profit < 0 })} variant="bodyBold" noMargin>
            {`${profit > 0 ? "" : "-"}${symbol}${Math.abs(profit)}${unit || ""}`}
          </Typography>
        </div>
        <div className={css.indicator}>
          <Typography variant="labelRegular">{t("margin")}</Typography>
          <Typography className={classNames({ [css.negative]: profit < 0 })} variant="bodyBold" noMargin>
            {margin ? `${margin}%` : "-"}
          </Typography>
        </div>
      </div>
    </>
  );
};
