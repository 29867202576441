import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { FeatureFlag } from "PFTypes";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";

import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { BookingFormValues } from "../booking_form_provider";

type UseInitialGlobalCategory = {
  enabled: boolean;
  setValue: UseFormSetValue<BookingFormValues>;
};

export const useInitialGlobalCategory = ({ enabled, setValue }: UseInitialGlobalCategory) => {
  const { activity: initialActivity } = useBookingActivityContext(); // the Booking Form was open in context of this activity
  const { getBookingCategory, mainCategory } = useBookingCategories();
  const isBookingModuleEnabled = useIsFeatureEnabled()(FeatureFlag.BookingModule);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    let category;
    if (initialActivity?.booking_category_id) {
      category = getBookingCategory(initialActivity.booking_category_id) ?? undefined;
    } else {
      category = isBookingModuleEnabled ? undefined : mainCategory ?? undefined;
    }
    setValue("globalCategory", category);
  }, [initialActivity]);
};
