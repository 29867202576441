import {
  BookingChange,
  OverbookingsManagementMode,
  SuggestedChangeAction,
  SuggestedChangesPayload
} from "PFCore/services/bookings_suggested_changes";

import { BookingFormValues } from "../booking_form/booking_form_provider";
import { isGroupOption } from "../booking_form/workforce_member_select/workforce_member_select_dropdown";

export const bookingFormValuesToSuggestedChangesData = (
  bookingFormValues: BookingFormValues,
  overbookedDates: string[],
  mode: OverbookingsManagementMode | null
): SuggestedChangesPayload | null => {
  if (!bookingFormValues?.workforceMember || isGroupOption(bookingFormValues.workforceMember) || !mode) {
    return null;
  }

  const profileId = bookingFormValues.workforceMember.id;
  const bookings = bookingFormValues.bookings.map((booking) => ({
    id: booking?.id,
    startDate: booking.startDate,
    endDate: booking.endDate,
    requirementValue: booking.requirement?.value,
    requirementType: booking.requirement.type,
    category: booking.category ?? undefined
  }));

  return {
    profileId,
    overbookedDates,
    mode,
    bookings
  };
};

export const applySuggestedChanges = (
  bookingFormValues: BookingFormValues,
  suggestedChanges: BookingChange[]
) => {
  const { bookings } = bookingFormValues;

  const updatedBookings = bookings.flatMap((booking, index) => {
    const suggestedChange = suggestedChanges[index];

    const isDeleteAction = suggestedChange.changes.find(
      (change) => change.action === SuggestedChangeAction.Delete
    );
    if (isDeleteAction) {
      return [booking];
    }

    if (suggestedChange.changes.length === 0) {
      return [booking];
    }

    return suggestedChange.changes.map((change) => {
      if (change.data) {
        return {
          ...booking,
          id: change.action === SuggestedChangeAction.Create ? undefined : booking.id,
          startDate: change.data.startDate,
          endDate: change.data.endDate,
          requirement: {
            value: change.data.requirementValue,
            type: booking.requirement.type
          }
        };
      }
      return booking;
    });
  });

  return {
    ...bookingFormValues,
    bookings: updatedBookings
  };
};
