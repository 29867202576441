import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { merge } from "lodash";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import {
  fetchActivityHistory,
  FetchActivityHistoryResponse
} from "PFCore/services/activity/activity_history";
import { QueryOptions, QueryParams } from "PFTypes";

import { useActivityHistoryContext } from "../activity_history_context";
import { activityHistoryKeys } from "./query_keys";

export const useActivityHistory = (
  params?: QueryParams,
  options: QueryOptions<FetchActivityHistoryResponse> = {}
) => {
  const { activity } = useActivityHistoryContext();

  return useQuery<FetchActivityHistoryResponse, AxiosError>(
    activityHistoryKeys.list(activity?.id || "", params),
    () => fetchActivityHistory(activity!.id, merge({ page: 1, perPage: PER_PAGE_UNPAGINATED }, params)),
    {
      enabled: !!activity?.id,
      retry: false,
      ...options
    }
  );
};
