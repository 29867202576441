import { Query } from "@tanstack/react-query";
import isNil from "lodash/isNil";

export type Booking = { id: number } & Record<string, unknown>;

export const bookingKeys = {
  all: () => ["bookings"],
  list: (params?: unknown, external?: boolean) => [
    ...bookingKeys.all(),
    "list",
    ...(((params && (Array.isArray(params) ? params : [params])) as Array<unknown>) || []),
    ...(!isNil(external) ? [external] : [])
  ],
  single: (bookingId?: number, profileId?: number, external?: boolean) => [
    ...bookingKeys.all(),
    bookingId,
    ...(profileId ? [profileId] : []),
    ...(!isNil(external) ? [external] : [])
  ],
  isBookingQuery: (query: Query) => query.queryKey[0] === "bookings"
};

export const bookingTemplateKeys = {
  all: () => ["booking_templates"],
  list: (params?: unknown) => [
    ...bookingTemplateKeys.all(),
    "list",
    ...(((params && (Array.isArray(params) ? params : [params])) as Array<unknown>) || [])
  ],
  single: (id: number | undefined) => [...bookingTemplateKeys.all(), id?.toString()]
};
