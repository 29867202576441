import { decamelizeKeys } from "humps";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import api from "PFCore/api";
import { Booking, PaginatedCollection } from "PFTypes";
import { Id } from "PFTypes/id";

type FetchBookingsPayload = {
  profileId: Id;
  dateRange?: { min: string; max: string };
  perPage?: number;
  activityId?: number | boolean;
};

export const fetchBookings = ({
  profileId,
  activityId,
  dateRange,
  perPage = PER_PAGE_UNPAGINATED
}: FetchBookingsPayload): Promise<PaginatedCollection<Booking[]>> =>
  api({
    url: `profiles/${profileId}/bookings`,
    method: "GET",
    params: { dateRange, perPage, ...(activityId ? { activityId } : null) }
  }).then((resp) => decamelizeKeys(resp) as PaginatedCollection<Booking[]>);

export const fetchCertificate = (id) => api({ url: `certificates/${id}` });

export const fetchCertificates = (profileId, page = 1, perPage = 10, customValueIds: number[] = []) =>
  api({
    url: `certificates?profile_id=${profileId}`,
    params: {
      page,
      perPage,
      ...(customValueIds.length > 0 ? { customValueIds } : {})
    }
  });

export const createCertificate = (payload) =>
  api({
    method: "POST",
    url: "certificates",
    data: payload
  });

export const updateCertificate = (payload) =>
  api({
    method: "PUT",
    url: `certificates/${payload.id}`,
    data: payload
  });

export const deleteCertificate = (id) =>
  api({
    method: "DELETE",
    url: `certificates/${id}`
  });
