import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { useGrowl } from "PFApp/use_growl";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useBooking } from "PFCore/hooks/queries";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { GroupBookingsQuestionsOption } from "../../booking_questions/group_bookings_questions";
import {
  clearCategoryOrPhaseErrors,
  getSubmitErrorMessageTranslationKey,
  getSubmitSuccessMessageTranslationKey,
  setCategoryOrPhaseErrors
} from "../booking_form.utils";
import { BookingFormValues } from "../booking_form_provider";
import {
  BookingFormData,
  BookingFormDataCreateMode,
  BookingFormDataEditOrCloneMode,
  BookingFormMode
} from "../use_booking_form";
import { isGroupOption } from "../workforce_member_select/workforce_member_select_dropdown";
import { usePostSubmitActions } from "./use_post_submit_actions";
import { useSubmitUpdate } from "./use_submit_update";

export type BookingResponse = {
  id: number;
};
export type OnSuccessData = {
  profileId: number | undefined;
  activityId: number | undefined;
  parentActivityIds: number[];
};

export type UseHandleSubmit = {
  initialData: BookingFormData;
  mode: BookingFormMode;
  onSuccess?: (data: OnSuccessData) => void | Promise<any>;
  onError: () => void;
  formMethods: UseFormReturn<BookingFormValues>;
};

type UseHandleSubmitReturn = {
  handleSubmit: (values: BookingFormValues) => Promise<void>;
  isLoading: boolean;
};

export const useHandleSubmit = ({
  initialData,
  mode,
  onSuccess,
  onError,
  formMethods
}: UseHandleSubmit): UseHandleSubmitReturn => {
  const growl = useGrowl();
  const { t } = useTranslation("bookingModule");
  const { parentActivity } = useBookingActivityContext();

  const { setError, clearErrors } = formMethods;

  const bookingId =
    mode === BookingFormMode.Create ? undefined : (initialData as BookingFormDataEditOrCloneMode).bookingId;
  const { data: booking } = useBooking(bookingId, initialData.profileId, { enabled: !!bookingId });

  const submitUpdate = useSubmitUpdate({ mode, initialBooking: booking });

  const [isLoading, setIsLoading] = useState(false);
  const { getFormattedErrors } = useResponseErrors();

  const shortlistId =
    mode === BookingFormMode.Create ? (initialData as BookingFormDataCreateMode)?.shortlistId : undefined;
  const bookingGroupId = booking?.booking_group_id;
  const { postSubmitActions } = usePostSubmitActions({ initialData, mode, shortlistId, bookingGroupId });

  const handleSubmit = async (values: BookingFormValues) => {
    const { bookings, workforceMember, activityId, updateGroupOptionSelected } = values;
    if (isEmpty(bookings)) {
      return;
    }

    clearCategoryOrPhaseErrors(mode, bookings, clearErrors, "submit");
    setIsLoading(true);

    return submitUpdate(values)
      .then(async (bookingsResponse) => {
        await postSubmitActions(bookingsResponse, values);
        await onSuccess?.({
          profileId: isGroupOption(workforceMember) ? undefined : workforceMember?.id,
          activityId,
          parentActivityIds: parentActivity?.id ? [parentActivity.id] : []
        });
        const isAffectingGroup =
          isGroupOption(workforceMember) ||
          (!isNil(booking?.booking_group_id) &&
            updateGroupOptionSelected === GroupBookingsQuestionsOption.GroupAll);
        const { actionId, messageKey } = getSubmitSuccessMessageTranslationKey(mode, isAffectingGroup);
        growl({
          id: actionId,
          kind: "success",
          message: t(messageKey),
          hidePreviousByTypeAndId: true,
          ttl: 5000
        });
        setIsLoading(false);
      })
      .catch(({ response }) => {
        const responseErrors = getFormattedErrors(response) || {};
        setIsLoading(false);
        if (Object.keys(responseErrors).length > 0) {
          responseErrors.base && growl({ kind: "error", message: responseErrors.base });
          setCategoryOrPhaseErrors(mode, bookings, responseErrors, setError, clearErrors, "submit");
          return;
        }
        growl({
          kind: "error",
          message: t(getSubmitErrorMessageTranslationKey(mode))
        });
        onError?.();
      });
  };

  return {
    handleSubmit,
    isLoading
  };
};
