import { concat } from "lodash";
import { getMatchDisplayValue } from "PFCore/helpers/match_value";
import { useMatchesInfinite } from "PFCore/hooks/queries/matches/use_matches";
import { Availability, Booking, OrderMeta, Profile } from "PFTypes";

import { prepareMatchesPayload } from "./use_reassign_matches.utils";

const formatScore = (score: number | null | undefined) => getMatchDisplayValue(score, { digits: 0 });

export type MatchesTableEntry = {
  profile: Profile;
  matchScore: string | undefined;
  availabilityScore: string | undefined;
};

export type MatchesResponse = {
  matchesData: MatchesTableEntry[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  lastRefreshAt: string | null;
  orderMeta: OrderMeta | undefined;
};

type UseMatches = {
  searchValue: string;
  order: any;
  booking: Booking;
  availability: Availability | undefined;
};

type UseMatchesReturn = {
  matchesResponse: MatchesResponse;
};

export const useReassignMatches = ({
  searchValue,
  order,
  booking,
  availability
}: UseMatches): UseMatchesReturn => {
  const {
    data: matchesPaginatedData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useMatchesInfinite(
    booking.activity_id ?? -1,
    {
      perPage: 10,
      ...prepareMatchesPayload(searchValue, availability, order)
    },
    {
      keepPreviousData: true
    }
  );

  const mergedPages = concat(...(matchesPaginatedData?.pages.map(({ entries }) => entries) || []));

  const meta =
    matchesPaginatedData && matchesPaginatedData.pages.length > 0
      ? matchesPaginatedData?.pages[0].meta
      : undefined;
  const lastRefreshAt =
    meta && meta.timestamps && "matched_at" in meta.timestamps ? meta.timestamps.matched_at : null;

  const matchesData = mergedPages
    .filter(({ profile }) => profile.id !== booking.profile_id)
    .map(({ profile, scores }) => ({
      profile,
      matchScore: formatScore(scores.normalized_score),
      availabilityScore: formatScore(scores.availability_score)
    }));

  const orderMeta = meta?.order;

  const matchesResponse = {
    matchesData,
    fetchNextPage,
    hasNextPage: !!hasNextPage,
    isFetching,
    isFetchingNextPage,
    lastRefreshAt,
    orderMeta
  };

  return {
    matchesResponse
  };
};
