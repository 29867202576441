import { Typography } from "PFComponents/typography";
import { BookingChange, SuggestedChangeAction } from "PFCore/services/bookings_suggested_changes";
import { BookingCategory, RequirementType } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import BookingPill from "../../../../components/pills/booking_pill";
import { BookingPillContent } from "./booking_pill_content";
import { ChangedBooking } from "./changed_booking";
import { DeleteBookingMessage } from "./delete_booking_message";
import css from "./suggested_changes.module.scss";
import { getTextColor } from "./suggested_changes.utils";
import { useFormatBookingDates } from "./use_format_booking_dates";

type SuggestedChangeProps = {
  bookingChange: BookingChange;
  category: BookingCategory;
};

export const SuggestedChanges = ({ bookingChange, category }: SuggestedChangeProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });
  const { booking } = bookingChange;

  const { formatBookingDates } = useFormatBookingDates();

  const originalBookingDates = formatBookingDates(booking.startDate, booking.endDate);

  const isSingleChange =
    bookingChange.changes.length === 1 && bookingChange.changes[0].action !== SuggestedChangeAction.Delete;

  const isSingleDeleteChange =
    bookingChange.changes.length === 1 && bookingChange.changes[0].action === SuggestedChangeAction.Delete;

  const textColor = getTextColor(category);

  const requirementValueHasChanged =
    booking.requirementType === RequirementType.TotalHours &&
    !!bookingChange.changes.find(
      (change) =>
        !!change.data?.requirementValue && change.data?.requirementValue !== booking.requirementValue
    );

  return (
    <div className={css.root}>
      <Typography variant="bodyBold" tag="span">
        {category.display_as}
      </Typography>
      <div
        className={css.changesContainer}
        style={{
          flexDirection: isSingleChange ? "row" : "column",
          justifyContent: isSingleChange ? "space-between" : "flex-start"
        }}
      >
        <div
          className={css.bookingContainer}
          style={{
            width: isSingleChange ? "50%" : "100%"
          }}
        >
          {isSingleDeleteChange ? (
            <DeleteBookingMessage />
          ) : (
            <Typography variant="bodyRegular" tag="span">
              {t("originalBooking")}
            </Typography>
          )}
          <BookingPill
            title={originalBookingDates}
            category={category}
            className={css.bookingPill}
            style={{
              color: textColor,
              borderWidth: 1
            }}
          >
            <BookingPillContent
              startDate={booking.startDate}
              endDate={booking.endDate}
              requirementValue={booking.requirementValue}
              requirementValueHasChanged={requirementValueHasChanged}
            />
          </BookingPill>
        </div>

        {!isSingleDeleteChange && (
          <div
            className={css.bookingContainer}
            style={{
              width: isSingleChange ? "50%" : "100%"
            }}
          >
            <Typography variant="bodyRegular" tag="span">
              {t("newBooking")}
            </Typography>
            {bookingChange.changes.map((change, index) => (
              <ChangedBooking
                key={index}
                suggestedChange={change}
                category={category}
                requirementValueHasChanged={requirementValueHasChanged}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
