import { Divider } from "PFComponents/divider";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import { Typography } from "PFComponents/typography";
import { useBookingsSuggestedChanges } from "PFCore/hooks/queries/bookings/suggested_changes/use_bookings_suggested_changes";
import { BookingChange, SuggestedChangesPayload } from "PFCore/services/bookings_suggested_changes";
import { BookingCategory } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./overbookings_management_modal.module.scss";
import { SuggestedChanges } from "./suggested_changes";

type OverbookingsManagementModalProps = {
  onClose: () => void;
  onConfirm: (bookingChanges: BookingChange[]) => Promise<void>;
  bookingsData: SuggestedChangesPayload | null;
  category: BookingCategory | undefined;
};

export const OverbookingsManagementModal = ({
  onConfirm,
  onClose,
  bookingsData,
  category
}: OverbookingsManagementModalProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { data: suggestedChangesData, isLoading } = useBookingsSuggestedChanges({
    payload: bookingsData,
    options: {
      enabled: !!bookingsData,
      cacheTime: 0
    }
  });

  if (!category) {
    return null;
  }

  const changedBookings =
    suggestedChangesData?.filter((bookingChange) => bookingChange.changes.length > 0) ?? [];

  return (
    <Modal
      title={t("bookingModule:bookings.createWithOverbooking.modal.title")}
      labelOK={t("translation:confirm")}
      onOK={() => onConfirm(suggestedChangesData ?? [])}
      onClose={onClose}
    >
      <div className={css.root}>
        <Typography variant="bodyRegular" tag="span">
          {t("bookingModule:bookings.createWithOverbooking.modal.information")}
        </Typography>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <div>
            {changedBookings.map((bookingChange, index) => (
              <>
                <SuggestedChanges key={index} category={category} bookingChange={bookingChange} />
                {index !== changedBookings.length - 1 && <Divider className={css.divider} />}
              </>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
