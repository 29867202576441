import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./suggested_changes.module.scss";

export const DeleteBookingMessage = () => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });

  return (
    <div className={css.deleteBookingMessage}>
      <Icon name="warning" color="paletteOrange0" />
      <Typography variant="bodyRegular" tag="span">
        {t("deleteInfo")}
      </Typography>
    </div>
  );
};
