import { Options as PopperOptions } from "@popperjs/core";
import classNames from "classnames";
import { SearchAutocomplete } from "PFApp/components/search_autocomplete";
import {
  SearchConfig,
  SearchEntityData
} from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useRecentSearches } from "PFCore/helpers/use_recent_searches";
import { IconName } from "PFTheme/graphics/icons";
import { EventKey } from "PFTypes/event_key";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

import css from "./search_input.module.scss";
import { useSearchInput } from "./use_search_input";

const INPUT_VALUE_MAX_LENGTH = 255;
export const AUTOCOMPLETE_ACTION_ICON_ID = "autocomplete-action-icon";

type SearchInputProps = {
  term: string;
  onTermChange: (newTerm: string, entity?: SearchEntityData | null) => void;
  qaId?: string;
  placeholder?: string;
  disabled?: boolean;
  autocompleteConfig?: SearchConfig;
  pageKey: RecentSearchesPageKey;
  includeRecentSearches?: boolean;
  popperOptions?: Partial<PopperOptions>;
  classes?: {
    root?: string;
    dropdown?: string;
  };
};

export const SearchInput = ({
  term,
  onTermChange,
  qaId,
  placeholder,
  disabled,
  autocompleteConfig = [],
  pageKey,
  includeRecentSearches = true,
  popperOptions,
  classes = {}
}: SearchInputProps) => {
  const { t } = useTranslation("translation");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef(null);

  const { getRecentSearches, saveSearchAsRecent } = useRecentSearches(pageKey);

  useEffect(() => {
    if (includeRecentSearches) {
      saveSearchAsRecent(term);
    }
  }, [term]);

  const {
    localTerm,
    isDropdownOpened,
    setIsDropdownOpened,
    setLocalTerm,
    handleClear,
    handleSubmit,
    handleInputChange,
    handleBlur,
    handleTermChange
  } = useSearchInput({
    term,
    onTermChange,
    withUrlChanges: false,
    searchInputRef
  });

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(inputWrapperRef.current, popperElement, {
    placement: "bottom-start",
    ...popperOptions
  });

  const handleKeyDown = (event) => {
    if (event.key === EventKey.Enter) {
      handleSubmit();
    }
    // TODO: [PROF-7192] It should be refactored as a part of Dropdown refactor ticket
    // This is a hack to make the dropdown work with keyboard navigation
    // The same implementation is done in select.tsx
    // @ts-ignore
    dropdownRef.current && dropdownRef.current.handleKeyDown(event);
  };

  const isSearchIconDisplayed = !term || isDropdownOpened;
  const isSearchActionDisabled = disabled || (!localTerm && !term);
  const withAutocomplete = includeRecentSearches || !!autocompleteConfig.length;
  const showAutocomplete = withAutocomplete && isDropdownOpened;

  const actions = [
    {
      id: AUTOCOMPLETE_ACTION_ICON_ID,
      name: (isSearchIconDisplayed ? "search" : "cross") as IconName,
      onClick: isSearchIconDisplayed ? handleSubmit : handleClear,
      disabled: disabled || (!localTerm && !term),
      title: isSearchIconDisplayed ? t("applyTheSearchTerm") : t("clearTheSearchTerm")
    }
  ];

  return (
    <div className={classNames(css.search, classes.root)} onBlur={handleBlur} role="searchbox">
      <div ref={inputWrapperRef} className={css.inputWrapper}>
        <InputFieldSet
          ref={searchInputRef}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onClick={() => setIsDropdownOpened(true)}
          onBlur={handleBlur}
          placeholder={placeholder ?? t("search")}
          value={localTerm}
          qaId={qaId}
          disabled={disabled}
          minVisibleMaxLengthFactor={2}
          maxLength={INPUT_VALUE_MAX_LENGTH}
          inputClassName={css.searchInput}
          role="combobox"
          aria-autocomplete="list"
          aria-expanded={showAutocomplete}
          actions={actions}
        />
      </div>
      {showAutocomplete && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 25 }} {...attributes.popper}>
          <SearchAutocomplete
            dropdownRef={dropdownRef}
            config={autocompleteConfig}
            recentSearches={includeRecentSearches ? getRecentSearches(localTerm) : []}
            term={localTerm}
            onTermChange={(text, entity) => {
              setLocalTerm(text);
              handleTermChange(text, entity);
            }}
            onClose={() => setIsDropdownOpened(false)}
            showFooter={!isSearchActionDisabled}
            classes={{ dropdown: classes.dropdown }}
          />
        </div>
      )}
    </div>
  );
};
