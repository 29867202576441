import { useBooking } from "PFCore/hooks/queries";

import BookingActivityContextProvider from "../../parts/providers/booking_activity_context_provider";
import { useBookingFormContext } from "./booking_form_context_provider";
import { BookingFormProvider } from "./booking_form_provider";
import {
  BookingFormDataCreateMode,
  BookingFormDataEditOrCloneMode,
  BookingFormMode
} from "./use_booking_form";
import { OnSuccessData } from "./use_handle_submit/use_handle_submit";

type BookingFormWrapperProps = {
  onSuccess?: (data: OnSuccessData) => Promise<unknown>;
  goToDate?: (date: Date) => void;
};

export const BookingFormWrapper = ({ onSuccess, goToDate }: BookingFormWrapperProps) => {
  const {
    modal: {
      state: { isOpen, data, props: modalProps }
    }
  } = useBookingFormContext();

  const bookingFormMode = modalProps?.mode ?? BookingFormMode.Create;

  const bookingId =
    bookingFormMode === BookingFormMode.Create
      ? undefined
      : (data as BookingFormDataEditOrCloneMode).bookingId;
  const { data: booking } = useBooking(bookingId, data?.profileId, { enabled: !!bookingId });

  const activityId =
    bookingFormMode === BookingFormMode.Create
      ? (data as BookingFormDataCreateMode).activityId
      : booking?.activity?.id;

  const handleSuccess = async (data: OnSuccessData) => {
    onSuccess?.(data);
    modalProps?.onSuccess?.(data);
  };

  return (
    <BookingActivityContextProvider id={activityId}>
      <BookingFormProvider
        show={isOpen}
        initialData={data}
        initialBooking={booking}
        onSuccess={handleSuccess}
        mode={bookingFormMode}
        goToDate={goToDate}
      />
    </BookingActivityContextProvider>
  );
};
