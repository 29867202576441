import { useActivityTemplateType } from "PFApp/activities/hooks";
import { Button } from "PFComponents/button";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { Activity, Availability, Booking, PermissionRule } from "PFTypes";
import React from "react";

import { useReassignActionApi } from "../../../booking_module_calendar/context/reassign_action_context_provider";

type ReassignButtonProps = {
  booking: Booking;
  activity: Activity | undefined;
  isEngagement: boolean;
  availability: Availability | undefined;
};

export const ReassignButton = ({ booking, activity, isEngagement, availability }: ReassignButtonProps) => {
  const { openSidePanel } = useReassignActionApi();

  const isPermittedTo = useIsCurrentUserPermittedTo();
  const canReassignBookings = isPermittedTo(PermissionRule.ReassignBookings);
  const { isAudit, isRole } = useActivityTemplateType(activity);

  const isAuditRoleRelated = isAudit && isRole;
  const isNotPermitted = isAuditRoleRelated || (isEngagement && !canReassignBookings);

  if (isNotPermitted) {
    return null;
  }

  return (
    <Button
      icon="reassign"
      onClick={() => {
        openSidePanel({
          activityName: booking.activity?.name,
          activity,
          sourceProfileId: booking.profile_id,
          booking,
          isEngagement,
          availability
        });
      }}
      kind="secondary"
    />
  );
};
