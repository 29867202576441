import BookingPill from "PFApp/components/pills/booking_pill";
import { SuggestedChange } from "PFCore/services/bookings_suggested_changes";
import { BookingCategory } from "PFTypes";
import React from "react";

import { BookingPillContent } from "./booking_pill_content";
import css from "./suggested_changes.module.scss";
import { getTextColor } from "./suggested_changes.utils";
import { useFormatBookingDates } from "./use_format_booking_dates";

type ChangedBookingProps = {
  suggestedChange: SuggestedChange;
  category: BookingCategory;
  requirementValueHasChanged: boolean;
};

export const ChangedBooking = ({
  suggestedChange,
  category,
  requirementValueHasChanged
}: ChangedBookingProps) => {
  const { formatBookingDates } = useFormatBookingDates();

  const bookingDates =
    suggestedChange.data?.startDate && suggestedChange.data?.endDate
      ? formatBookingDates(suggestedChange.data.startDate, suggestedChange.data.endDate)
      : undefined;

  const textColor = getTextColor(category);

  return (
    <div className={css.changedBookingContainer}>
      <BookingPill
        title={bookingDates}
        category={category}
        className={css.bookingPill}
        style={{
          color: textColor,
          borderWidth: 1
        }}
      >
        <BookingPillContent
          startDate={suggestedChange.data?.startDate}
          endDate={suggestedChange.data?.endDate}
          requirementValue={suggestedChange.data?.requirementValue}
          requirementValueHasChanged={requirementValueHasChanged}
        />
      </BookingPill>
    </div>
  );
};
