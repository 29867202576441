import { BookingNotPermittedMessage } from "PFApp/booking/components/booking_not_permitted_message";
import { SingleBookingDetails } from "PFApp/booking/parts/overview/detail_view/booking_detail/booking_detail_content/booking_siblings/single_booking_details";
import { LoadingDots } from "PFComponents/loading_dots";
import { useBooking } from "PFCore/hooks/queries";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { RefObject } from "react";
import { useWatch } from "react-hook-form";

import { BookingFormValues } from "../booking_form_provider";
import { BookingDataItemLimitation, BookingFormMode } from "../use_booking_form";
import { isGroupOption } from "../workforce_member_select/workforce_member_select_dropdown";
import { RangeItem } from "./range_item";

type BookingItemProps = {
  itemIndex: number;
  monthsForwardLimit: number;
  portalRef: RefObject<HTMLDivElement>;
  mode: BookingFormMode;
};

export const BookingItem = ({ itemIndex, monthsForwardLimit, portalRef, mode }: BookingItemProps) => {
  const { getCategory } = useBookingCategories();
  const { id, bookingTemplateId, limitation } = useWatch<BookingFormValues>({
    name: `bookings.${itemIndex}`
  });
  const workforceMember = useWatch<BookingFormValues>({ name: "workforceMember" });
  const profileId = isGroupOption(workforceMember) ? undefined : workforceMember?.id;

  const isHidden = limitation === BookingDataItemLimitation.Hidden;
  const isReadonly = limitation === BookingDataItemLimitation.Readonly;

  const { data: booking } = useBooking(id, profileId, { enabled: isReadonly && !isHidden && !!id });
  const { data: bookingTemplate } = useBookingTemplate(bookingTemplateId, {
    enabled: isReadonly && !isHidden && !!bookingTemplateId
  });

  if (isHidden) {
    return <BookingNotPermittedMessage />;
  }

  if (isReadonly) {
    return booking ? (
      <SingleBookingDetails
        booking={booking}
        bookingTemplate={bookingTemplate}
        category={getCategory(booking)!}
      />
    ) : (
      <LoadingDots />
    );
  }

  return (
    <RangeItem
      itemIndex={itemIndex}
      monthsForwardLimit={monthsForwardLimit}
      portalRef={portalRef}
      mode={mode}
    />
  );
};
