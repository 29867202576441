import { Typography } from "PFComponents/typography";
import React from "react";

import css from "./suggested_changes.module.scss";
import { getRequirementValue } from "./suggested_changes.utils";
import { useFormatBookingDates } from "./use_format_booking_dates";

type BookingPillContentProps = {
  startDate: string | undefined;
  endDate: string | undefined;
  requirementValue: number | undefined;
  requirementValueHasChanged: boolean;
};

export const BookingPillContent = ({
  startDate,
  endDate,
  requirementValue,
  requirementValueHasChanged
}: BookingPillContentProps) => {
  const { formatBookingDates } = useFormatBookingDates();

  const bookingDates = startDate && endDate ? formatBookingDates(startDate, endDate) : undefined;

  return (
    <div className={css.bookingPillContent}>
      {bookingDates && (
        <Typography variant="bodyBold" tag="span">
          {bookingDates}
        </Typography>
      )}
      {requirementValueHasChanged && requirementValue && (
        <Typography variant={bookingDates ? "bodyRegular" : "bodyBold"} tag="span">
          {getRequirementValue(requirementValue)}
        </Typography>
      )}
    </div>
  );
};
