import { isNil } from "lodash";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import { useBooking, useBookingInvalidate, useBookings } from "PFCore/hooks/queries";
import { Booking } from "PFTypes";
import { useCallback, useMemo } from "react";

const getParams = (profileId?: number, activityId?: number, bookingTemplateId?: number) => ({
  profileId: profileId ?? -1,
  activityId,
  bookingTemplateId,
  perPage: PER_PAGE_UNPAGINATED
});

type BookingSiblingsProps = {
  bookingId?: number;
  profileId?: number;
  activityId?: number;
  bookingTemplateId?: number;
  enabled?: boolean;
};
type UseBookingSiblingsReturn = {
  data: Booking[];
  isFetching: boolean;
  invalidate: VoidFunction;
};

export const useBookingSiblings = ({
  bookingId,
  profileId,
  activityId,
  bookingTemplateId,
  enabled = true
}: BookingSiblingsProps): UseBookingSiblingsReturn => {
  const shouldFetchMultipleBookings = !isNil(profileId) && (!isNil(activityId) || !isNil(bookingTemplateId));
  const shouldFetchSingleBooking = !!bookingId && !shouldFetchMultipleBookings;
  const { invalidateList: invalidateBookingsList, invalidate: invalidateBooking } = useBookingInvalidate();

  const { data: siblingBookings, isFetching: areBookingsFetching } = useBookings(
    getParams(profileId, activityId, bookingTemplateId),
    {
      enabled: enabled && shouldFetchMultipleBookings
    }
  );
  const { data: booking, isFetching: isBookingFetching } = useBooking(bookingId, profileId, {
    enabled: enabled && shouldFetchSingleBooking
  });

  const invalidate = useCallback(() => {
    if (shouldFetchMultipleBookings) {
      invalidateBookingsList(getParams(profileId, activityId, bookingTemplateId));
    } else if (shouldFetchSingleBooking) {
      invalidateBooking(bookingId);
    }
  }, [
    shouldFetchMultipleBookings,
    shouldFetchSingleBooking,
    profileId,
    activityId,
    bookingTemplateId,
    bookingId
  ]);

  return useMemo(() => {
    if (!enabled) {
      return {
        data: [],
        isFetching: false,
        invalidate: () => {}
      };
    }
    if (!shouldFetchMultipleBookings) {
      return {
        data: booking ? [booking] : [],
        isFetching: isBookingFetching,
        invalidate
      };
    }

    return {
      data: siblingBookings?.entries ?? [],
      isFetching: areBookingsFetching,
      invalidate
    };
  }, [
    enabled,
    shouldFetchMultipleBookings,
    booking,
    isBookingFetching,
    siblingBookings,
    areBookingsFetching,
    invalidate
  ]);
};
