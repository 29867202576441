import { isEmpty } from "lodash";
import { Availability, OrderOption } from "PFTypes";

const prepareFiltersParam = (searchValue: string, availability: Availability | undefined) => ({
  filters: {
    ...(!isEmpty(searchValue)
      ? {
          fields: {
            term: searchValue
          }
        }
      : {}),
    ...(availability ? { availability } : {})
  }
});

export const prepareMatchesPayload = (
  searchValue: string,
  availability: Availability | undefined,
  order: OrderOption | undefined
) => ({
  order,
  ...prepareFiltersParam(searchValue, availability)
});
