import isEmpty from "lodash/isEmpty";
import { LoadingDots } from "PFComponents/loading_dots/loading_dots";
import { Typography } from "PFComponents/typography";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { Booking } from "PFTypes";
import React, { useEffect, useRef } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Carousel } from "../../carousel";
import { BookingFormValues, BookingType } from "../booking_form_provider";
import { isSomeRequirementInvalid } from "../requirement_selector";
import { BookingDataItem, BookingFormData, BookingFormMode } from "../use_booking_form";
import { BookingItem } from "./booking_item";
import css from "./booking_range_column.module.scss";
import { useDefaultBookingsMapper } from "./use_default_bookings_mapper";
import { useInitialBookings } from "./use_initial_bookings";

type BookingRangeColumnProps = {
  initialData: BookingFormData;
  mode: BookingFormMode;
  activeBookingIndex: number;
  setActiveBookingIndex: React.Dispatch<React.SetStateAction<number>>;
  initialBooking?: Booking;
  errors?: Record<string, string>;
};

export const BookingRangeColumn = ({
  initialData,
  mode,
  initialBooking,
  activeBookingIndex,
  setActiveBookingIndex
}: BookingRangeColumnProps) => {
  const { data: currentAccount } = useCurrentAccount();
  const { t } = useTranslation("bookingModule");
  const portalRef = useRef(null);
  const bookingType = useWatch({ name: "bookingType" });

  const {
    fields: bookings,
    append,
    remove
  } = useFieldArray<BookingFormValues, "bookings", "key">({
    name: "bookings",
    keyName: "key",
    rules: {
      validate: (items: BookingDataItem[]) => !isEmpty(items) && !isSomeRequirementInvalid(items)
    }
  });
  const { loading: areInitialBookingsLoading } = useInitialBookings({
    initialData,
    mode,
    booking: initialBooking
  });

  const { getDefaultBooking } = useDefaultBookingsMapper({});

  const initialActiveIndex = Math.max(
    bookings.findIndex(({ id }) => id === initialBooking?.id),
    0
  );

  useEffect(() => {
    setActiveBookingIndex(initialActiveIndex);
  }, [initialActiveIndex]);

  useEffect(() => {
    if (activeBookingIndex > bookings?.length - 1) {
      setActiveBookingIndex(initialActiveIndex);
    }
  }, [bookings]);

  if (areInitialBookingsLoading) {
    return <LoadingDots className={css.loading} />;
  }

  const monthsForwardLimit = currentAccount.availability?.calculated_months_limit!;

  const addBooking = () => {
    append(getDefaultBooking());
  };

  const removeBooking = (index: number) => {
    remove(index);
  };

  const items = bookings.map(({ key }, itemIndex) => (
    <BookingItem
      key={key}
      itemIndex={itemIndex}
      monthsForwardLimit={monthsForwardLimit}
      portalRef={portalRef}
      mode={mode}
    />
  ));

  const isEditMode = mode === BookingFormMode.Edit;
  const isRepeated = bookingType === BookingType.Repeated;

  const header = (
    <Typography variant="bodyRegular" noMargin>
      {isEditMode && isRepeated
        ? t("bookings.repeatedBooking")
        : t("bookings.bookingOrdinalNumber", { current: activeBookingIndex + 1, total: items.length })}
    </Typography>
  );

  return (
    <>
      <section id="calendar_portal">
        <Carousel
          items={items}
          header={header}
          onAdd={!isEditMode ? addBooking : undefined}
          onDelete={!isEditMode ? removeBooking : undefined}
          activeIndex={activeBookingIndex}
          setActiveIndex={setActiveBookingIndex}
        />
      </section>
      <div ref={portalRef} />
    </>
  );
};
