import { useMatches } from "PFCore/hooks/queries/matches/use_matches";
import { useMatchesInvalidate } from "PFCore/hooks/queries/matches/use_matches_invalidate";
import { Activity, Match, MatchFetchState } from "PFTypes";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useActivityPermissions } from "../../hooks";
import { activityContextPlaceholder } from "../activity_page_context";

const useActivityPageMatches = (activity: Activity, currentProfile, params) => {
  const history = useHistory();
  const location = useLocation();
  const { invalidate: invalidateMatches } = useMatchesInvalidate();

  const [fetchState, setFetchState] = useState<MatchFetchState>(MatchFetchState.Pending);

  const handleError = ({ status }) => {
    if ([502, 504].includes(status)) {
      setFetchState(MatchFetchState.Timeout);
    } else {
      setFetchState(MatchFetchState.Error);
    }
  };

  const [matchesAttempts, setMatchesAttempts] = useState(0);

  const handleMatchesFetched = (matches) => {
    setMatchesAttempts((attempts) => attempts + 1);

    if (matches.meta.status !== "ready") {
      setTimeout(() => {
        invalidateMatches([activity.id]);
      }, (matchesAttempts + 3) * 700); // +3 to start on 1.5 or 2 seconds

      return;
    }

    //Happy path, matches are ready
    if (location.pathname.match("matches") && location.pathname.match("activities")) {
      history.replace({
        pathname: `/activities/${activity.id}/matches`,
        search: matchesFilters.page > 1 ? `page=${matchesFilters.page}` : undefined
      });
    }

    setFetchState(MatchFetchState.Synced);
    setMatchesAttempts(0);
  };

  const { canSeeMatches } = useActivityPermissions(activity);

  const [matchesFilters, setMatchesFilters] = useState({
    page: Number(params.page),
    per_page: 10,
    filters: {}
  });
  const [matchesEnabled, setMatchesEnabled] = useState(false);
  const matches = useMatches(activity.id, matchesFilters, {
    keepPreviousData: true,
    enabled: matchesEnabled,
    onSuccess: handleMatchesFetched,
    onError: handleError
  });

  const handleTaskFetched = useCallback(() => {
    if (!canSeeMatches) {
      return;
    }

    setFetchState(MatchFetchState.Pending);
    setMatchesFilters((filters) => ({ ...filters, page: Number(params.page) }));
    if (matchesEnabled) {
      invalidateMatches();
    } else {
      setMatchesEnabled(true);
    }
  }, [activity, canSeeMatches]);

  const updateMatchesParams = useCallback(
    (params) =>
      setMatchesFilters((currentParams) => ({
        ...currentParams,
        ...params
      })),
    []
  );

  useEffect(() => {
    handleTaskFetched();
  }, [activity, canSeeMatches]);

  const matchesMeta = matches.data?.meta || activityContextPlaceholder.matchesMeta;

  const { isFetching } = matches;

  return {
    matches: matches.data?.entries as Match[] | undefined,
    matchesMeta,
    matchesFetchState: isFetching ? MatchFetchState.Pending : fetchState,
    canSeeMatches,
    matchesParams: matchesFilters,
    updateMatchesParams,
    matchesReload: () => invalidateMatches()
  };
};

export default useActivityPageMatches;
