export enum RecentSearchesPageKey {
  Profiles = "profiles",
  WorkflowRoles = "workflow_roles",
  WorkflowEngagements = "workflow_engagements",
  InsightsRoles = "insights_roles",
  ActivityInterested = "activity_interested",
  ActivityMatches = "activity_matches",
  ActivityShortlist = "activity_shortlist",
  AnalyticsExport = "analytics_export",
  AuditPlanner = "audit_planner",
  BookingModuleProjectsRoles = "booking_module_projects_roles",
  BookingModuleProjectsEngagements = "booking_module_projects_engagements",
  BookingModuleWorkforce = "booking_module_workforce",
  BookingModuleReportsExport = "booking_module_reports_export",
  MarketplaceWorkOpportunities = "marketplace_work_opportunities",
  ProfileSkills = "profile_skills",
  BookingsCalendar = "bookings_calendar",
  BookingCategories = "booking_categories",
  BookingCalendars = "booking_calendars",
  SkillsFrameworks = "skills_frameworks",
  CustomValues = "custom_values",
  Skills = "skills",
  ReassignProfile = "reassign_profile"
}
export type RecentSearch = {
  value: string;
  timestamp: number;
};
