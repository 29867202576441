import { fetchProfile as fetchUserProfile } from "PFCore/services/profile";
import { Profile } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from "react";

import { BookingFormWrapper } from "./booking_form_wrapper";
import {
  OverbookingsManagementApiContext,
  OverbookingsManagementStateContext
} from "./overbookings_management_context/overbookings_management_context";
import { useOverbookingsManagement } from "./overbookings_management_context/use_overbookings_management";
import { BookingFormType, useBookingForm } from "./use_booking_form";
import { OnSuccessData } from "./use_handle_submit/use_handle_submit";

type BookingFormContextType = {
  modal: BookingFormType;
  fetchProfile: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
  loadingState: Record<string, boolean>;
  setLoadingState: Dispatch<SetStateAction<Record<string, boolean>>>;
};

const BookingFormContext = createContext({} as BookingFormContextType);

export type BookingFormConfig = {
  fetchProfile?: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
  onSuccess?: (data: OnSuccessData) => Promise<unknown>;
  goToDate?: (date: Date) => void;
};

export const BookingFormContextProvider = ({
  children,
  fetchProfile,
  onSuccess,
  goToDate
}: PropsWithChildren<BookingFormConfig>) => {
  const { overbookingsManagementApi, overbookingsManagementState } = useOverbookingsManagement();
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const modal = useBookingForm();
  const value = useMemo(
    () => ({
      modal,
      fetchProfile: fetchProfile || fetchUserProfile,
      loadingState,
      setLoadingState
    }),
    [modal, fetchProfile, loadingState]
  );

  return (
    <BookingFormContext.Provider value={value}>
      <OverbookingsManagementApiContext.Provider value={overbookingsManagementApi}>
        <OverbookingsManagementStateContext.Provider value={overbookingsManagementState}>
          {modal.state.isOpen && <BookingFormWrapper onSuccess={onSuccess} goToDate={goToDate} />}
          {children}
        </OverbookingsManagementStateContext.Provider>
      </OverbookingsManagementApiContext.Provider>
    </BookingFormContext.Provider>
  );
};

export const useBookingFormContext = () => useContext(BookingFormContext);
